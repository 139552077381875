/** @jsx jsx */
import {
  jsx, Flex,
} from 'theme-ui';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import _ from 'lodash';
import {
  useSpring, animated,
} from 'react-spring';

const MathSignsImg = styled(Flex)`
  width: 100%;
  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    background: linear-gradient(0deg, rgba(255,255,255,0.7) 0%, rgba(255,255,255,0) 100%);
  }
;
`;

const MathSigns = ({
  blur = true,
  y = 0,
}: {
  blur?: boolean;
  y?: number;
}) => (
  <MathSignsImg
    sx={{
      justifyContent: 'center',
      zIndex: -1,
    }}
  >
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 750 480"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <filter id="bigBlur">
        <feGaussianBlur
          in="SourceGraphic"
          stdDeviation={blur ? '3.2' : '0'}
        />
      </filter>
      <filter id="mediumBlur">
        <feGaussianBlur
          in="SourceGraphic"
          stdDeviation={blur ? '1.8' : '0'}
        />
      </filter>
      <filter id="smallBlur">
        <feGaussianBlur
          in="SourceGraphic"
          stdDeviation={blur ? '1.5' : '0'}
        />
      </filter>
      <animated.g id="big" style={{ transform: `translate3d(0,${y * 0.2}px,0)` }}>
        <path d="M641.067 143.905H625.004V140.311H641.067V143.905ZM630.504 135.811C630.504 135.103 630.738 134.53 631.207 134.093C631.686 133.655 632.301 133.436 633.051 133.436C633.791 133.436 634.4 133.655 634.879 134.093C635.369 134.52 635.613 135.093 635.613 135.811C635.613 136.509 635.379 137.077 634.91 137.514C634.442 137.941 633.822 138.155 633.051 138.155C632.27 138.155 631.65 137.936 631.192 137.499C630.733 137.061 630.504 136.499 630.504 135.811ZM630.504 148.499C630.504 147.79 630.738 147.218 631.207 146.78C631.686 146.343 632.301 146.124 633.051 146.124C633.791 146.124 634.4 146.343 634.879 146.78C635.369 147.207 635.613 147.78 635.613 148.499C635.613 149.197 635.379 149.764 634.91 150.202C634.442 150.629 633.822 150.843 633.051 150.843C632.27 150.843 631.65 150.624 631.192 150.186C630.733 149.749 630.504 149.186 630.504 148.499Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M616.82 221.564H600.758V217.97H616.82V221.564ZM606.258 213.47C606.258 212.762 606.492 212.189 606.961 211.752C607.44 211.314 608.055 211.095 608.805 211.095C609.544 211.095 610.154 211.314 610.633 211.752C611.123 212.179 611.367 212.752 611.367 213.47C611.367 214.168 611.133 214.736 610.664 215.174C610.195 215.601 609.576 215.814 608.805 215.814C608.024 215.814 607.404 215.595 606.945 215.158C606.487 214.72 606.258 214.158 606.258 213.47ZM606.258 226.158C606.258 225.45 606.492 224.877 606.961 224.439C607.44 224.002 608.055 223.783 608.805 223.783C609.544 223.783 610.154 224.002 610.633 224.439C611.123 224.866 611.367 225.439 611.367 226.158C611.367 226.856 611.133 227.424 610.664 227.861C610.195 228.288 609.576 228.502 608.805 228.502C608.024 228.502 607.404 228.283 606.945 227.845C606.487 227.408 606.258 226.845 606.258 226.158Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M547.175 145.292H531.112V141.698H547.175V145.292ZM536.612 137.198C536.612 136.49 536.847 135.917 537.316 135.479C537.795 135.042 538.409 134.823 539.159 134.823C539.899 134.823 540.508 135.042 540.987 135.479C541.477 135.907 541.722 136.479 541.722 137.198C541.722 137.896 541.487 138.464 541.019 138.901C540.55 139.328 539.93 139.542 539.159 139.542C538.378 139.542 537.758 139.323 537.3 138.886C536.842 138.448 536.612 137.886 536.612 137.198ZM536.612 149.886C536.612 149.177 536.847 148.604 537.316 148.167C537.795 147.729 538.409 147.511 539.159 147.511C539.899 147.511 540.508 147.729 540.987 148.167C541.477 148.594 541.722 149.167 541.722 149.886C541.722 150.584 541.487 151.151 541.019 151.589C540.55 152.016 539.93 152.229 539.159 152.229C538.378 152.229 537.758 152.011 537.3 151.573C536.842 151.136 536.612 150.573 536.612 149.886Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M661.702 82.7153L654.249 92.3716H666.468V96.0122H648.046V93.6528L656.952 82.4497L648.046 71.4185V69.0591H665.921V72.7153H654.296L661.702 82.1997V82.7153Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M764.38 214.458L756.927 224.115H769.146V227.755H750.724V225.396L759.63 214.193L750.724 203.162V200.802H768.599V204.458H756.974L764.38 213.943V214.458Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M604.865 378.097L597.412 387.753H609.631V391.394H591.209V389.035L600.115 377.832L591.209 366.8V364.441H609.084V368.097H597.459L604.865 377.582V378.097Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M388.613 19.4807L381.16 29.137H393.379V32.7776H374.957V30.4182L383.863 19.2151L374.957 8.18384V5.82446H392.832V9.48071H381.207L388.613 18.9651V19.4807Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M491.291 198.633L483.838 208.289H496.057V211.93H477.635V209.57L486.541 198.367L477.635 187.336V184.977H495.51V188.633H483.885L491.291 198.117V198.633Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M331.778 421.159L324.325 430.815H336.544V434.456H318.122V432.096L327.028 420.893L318.122 409.862V407.502H335.997V411.159H324.372L331.778 420.643V421.159Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M183.235 24.6821H169.267V20.9946H183.235V24.6821ZM183.235 31.4478H169.267V27.7603H183.235V31.4478Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M60.1984 85.7H46.2296V82.0125H60.1984V85.7ZM60.1984 92.4656H46.2296V88.7781H60.1984V92.4656Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M16.2794 200.802H2.31068V197.114H16.2794V200.802ZM16.2794 207.567H2.31068V203.88H16.2794V207.567Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M125.736 59.1404L118.283 68.7966H130.502V72.4373H112.08V70.0779L120.986 58.8748L112.08 47.8435V45.4841H129.955V49.1404H118.33L125.736 58.6248V59.1404Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M228.414 190.883L220.961 200.54H233.18V204.18H214.758V201.821L223.664 190.618L214.758 179.586V177.227H232.633V180.883H221.008L228.414 190.368V190.883Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M68.9005 354.522L61.4474 364.178H73.6662V367.819H55.2443V365.459L64.1505 354.256L55.2443 343.225V340.866H73.1193V344.522H61.4943L68.9005 354.006V354.522Z" fill="#D7E8F5" fillOpacity="0.6" />
      </animated.g>
      <animated.g id="medium" style={{ transform: `translate3d(0,${y * 0.7}px,0)` }} filter="url(#smallBlur)">
        <path d="M775.661 161.894H780.42V165.335H775.661V170.714H772.035V165.335H767.263V161.894H772.035V156.739H775.661V161.894Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M724.767 91.9858H711.671V88.5288H724.767V91.9858ZM724.767 98.3286H711.671V94.8716H724.767V98.3286Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M557.811 268.105H544.715V264.648H557.811V268.105ZM557.811 274.448H544.715V270.991H557.811V274.448Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M451.678 16.2729H438.582V12.8159H451.678V16.2729ZM451.678 22.6157H438.582V19.1587H451.678V22.6157Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M328.642 99.2488H315.546V95.7917H328.642V99.2488ZM328.642 105.592H315.546V102.135H328.642V105.592Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M663.723 242.221H650.627V238.764H663.723V242.221ZM663.723 248.564H650.627V245.107H663.723V248.564Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M752.731 132.357H737.171V128.875H752.731V132.357ZM742.499 124.516C742.499 123.83 742.726 123.275 743.18 122.851C743.644 122.427 744.239 122.215 744.966 122.215C745.682 122.215 746.273 122.427 746.737 122.851C747.211 123.265 747.448 123.82 747.448 124.516C747.448 125.192 747.221 125.742 746.767 126.166C746.313 126.58 745.713 126.787 744.966 126.787C744.209 126.787 743.609 126.575 743.165 126.151C742.721 125.727 742.499 125.182 742.499 124.516ZM742.499 136.807C742.499 136.121 742.726 135.566 743.18 135.142C743.644 134.718 744.239 134.506 744.966 134.506C745.682 134.506 746.273 134.718 746.737 135.142C747.211 135.556 747.448 136.111 747.448 136.807C747.448 137.483 747.221 138.033 746.767 138.457C746.313 138.871 745.713 139.078 744.966 139.078C744.209 139.078 743.609 138.866 743.165 138.442C742.721 138.018 742.499 137.473 742.499 136.807Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M349.485 251.513H333.924V248.031H349.485V251.513ZM339.253 243.672C339.253 242.986 339.48 242.431 339.934 242.007C340.398 241.583 340.993 241.371 341.72 241.371C342.436 241.371 343.027 241.583 343.491 242.007C343.965 242.421 344.202 242.976 344.202 243.672C344.202 244.348 343.975 244.898 343.521 245.322C343.067 245.736 342.467 245.942 341.72 245.942C340.963 245.942 340.363 245.73 339.919 245.307C339.475 244.883 339.253 244.338 339.253 243.672ZM339.253 255.963C339.253 255.277 339.48 254.722 339.934 254.298C340.398 253.874 340.993 253.662 341.72 253.662C342.436 253.662 343.027 253.874 343.491 254.298C343.965 254.712 344.202 255.267 344.202 255.963C344.202 256.639 343.975 257.189 343.521 257.613C343.067 258.027 342.467 258.233 341.72 258.233C340.963 258.233 340.363 258.021 339.919 257.598C339.475 257.174 339.253 256.629 339.253 255.963Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M267.607 98.3569H252.047V94.8755H267.607V98.3569ZM257.375 90.5161C257.375 89.8299 257.602 89.2749 258.056 88.8511C258.52 88.4272 259.115 88.2153 259.842 88.2153C260.558 88.2153 261.149 88.4272 261.613 88.8511C262.087 89.2648 262.324 89.8198 262.324 90.5161C262.324 91.1922 262.097 91.7422 261.643 92.166C261.189 92.5798 260.589 92.7866 259.842 92.7866C259.085 92.7866 258.485 92.5747 258.041 92.1509C257.597 91.7271 257.375 91.1821 257.375 90.5161ZM257.375 102.807C257.375 102.121 257.602 101.566 258.056 101.142C258.52 100.718 259.115 100.506 259.842 100.506C260.558 100.506 261.149 100.718 261.613 101.142C262.087 101.556 262.324 102.111 262.324 102.807C262.324 103.483 262.097 104.033 261.643 104.457C261.189 104.871 260.589 105.078 259.842 105.078C259.085 105.078 258.485 104.866 258.041 104.442C257.597 104.018 257.375 103.473 257.375 102.807Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M239.696 138.319H244.455V141.76H239.696V147.139H236.07V141.76H231.298V138.319H236.07V133.164H239.696V138.319Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M489.853 150.059H474.293V146.577H489.853V150.059ZM479.621 142.218C479.621 141.532 479.848 140.977 480.302 140.553C480.766 140.129 481.361 139.917 482.088 139.917C482.804 139.917 483.395 140.129 483.859 140.553C484.333 140.966 484.57 141.521 484.57 142.218C484.57 142.894 484.343 143.444 483.889 143.868C483.435 144.281 482.835 144.488 482.088 144.488C481.331 144.488 480.731 144.276 480.287 143.853C479.843 143.429 479.621 142.884 479.621 142.218ZM479.621 154.509C479.621 153.823 479.848 153.268 480.302 152.844C480.766 152.42 481.361 152.208 482.088 152.208C482.804 152.208 483.395 152.42 483.859 152.844C484.333 153.257 484.57 153.812 484.57 154.509C484.57 155.185 484.343 155.735 483.889 156.159C483.435 156.572 482.835 156.779 482.088 156.779C481.331 156.779 480.731 156.567 480.287 156.144C479.843 155.72 479.621 155.175 479.621 154.509Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M465.607 227.718H450.047V224.236H465.607V227.718ZM455.375 219.877C455.375 219.191 455.602 218.635 456.056 218.212C456.52 217.788 457.115 217.576 457.842 217.576C458.558 217.576 459.149 217.788 459.613 218.212C460.087 218.625 460.324 219.18 460.324 219.877C460.324 220.553 460.097 221.103 459.643 221.527C459.189 221.94 458.589 222.147 457.842 222.147C457.085 222.147 456.485 221.935 456.041 221.511C455.597 221.088 455.375 220.543 455.375 219.877ZM455.375 232.168C455.375 231.482 455.602 230.927 456.056 230.503C456.52 230.079 457.115 229.867 457.842 229.867C458.558 229.867 459.149 230.079 459.613 230.503C460.087 230.916 460.324 231.471 460.324 232.168C460.324 232.844 460.097 233.394 459.643 233.818C459.189 234.231 458.589 234.438 457.842 234.438C457.085 234.438 456.485 234.226 456.041 233.802C455.597 233.379 455.375 232.834 455.375 232.168Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M16.9616 164.996H1.40102V161.514H16.9616V164.996ZM6.72914 157.155C6.72914 156.469 6.95619 155.914 7.41029 155.49C7.87449 155.066 8.46986 154.854 9.19643 154.854C9.9129 154.854 10.5032 155.066 10.9674 155.49C11.4417 155.903 11.6788 156.458 11.6788 157.155C11.6788 157.831 11.4518 158.381 10.9977 158.805C10.5436 159.218 9.94317 159.425 9.19643 159.425C8.43959 159.425 7.83917 159.213 7.39516 158.79C6.95115 158.366 6.72914 157.821 6.72914 157.155ZM6.72914 169.446C6.72914 168.76 6.95619 168.205 7.41029 167.781C7.87449 167.357 8.46986 167.145 9.19643 167.145C9.9129 167.145 10.5032 167.357 10.9674 167.781C11.4417 168.194 11.6788 168.75 11.6788 169.446C11.6788 170.122 11.4518 170.672 10.9977 171.096C10.5436 171.509 9.94317 171.716 9.19643 171.716C8.43959 171.716 7.83917 171.504 7.39516 171.081C6.95115 170.657 6.72914 170.112 6.72914 169.446Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M692.627 352.587H681.082V350.004H692.627V352.587ZM685.035 346.77C685.035 346.261 685.203 345.849 685.54 345.535C685.885 345.22 686.326 345.063 686.865 345.063C687.397 345.063 687.835 345.22 688.179 345.535C688.531 345.842 688.707 346.253 688.707 346.77C688.707 347.272 688.539 347.68 688.202 347.994C687.865 348.301 687.419 348.455 686.865 348.455C686.304 348.455 685.858 348.297 685.529 347.983C685.2 347.668 685.035 347.264 685.035 346.77ZM685.035 355.889C685.035 355.38 685.203 354.968 685.54 354.654C685.885 354.339 686.326 354.182 686.865 354.182C687.397 354.182 687.835 354.339 688.179 354.654C688.531 354.961 688.707 355.373 688.707 355.889C688.707 356.391 688.539 356.799 688.202 357.113C687.865 357.42 687.419 357.574 686.865 357.574C686.304 357.574 685.858 357.417 685.529 357.102C685.2 356.788 685.035 356.383 685.035 355.889Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M182.491 187.615L185.639 184.406L182.491 181.197L184.234 179.474L187.351 182.663L190.479 179.474L192.222 181.197L189.074 184.406L192.222 187.615L190.479 189.338L187.351 186.159L184.234 189.338L182.491 187.615Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M165.37 330.453L168.518 327.243L165.37 324.034L167.113 322.311L170.23 325.5L173.358 322.311L175.101 324.034L171.953 327.243L175.101 330.453L173.358 332.175L170.23 328.997L167.113 332.175L165.37 330.453Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M62.5368 195.936L65.6847 192.727L62.5368 189.517L64.2799 187.794L67.3971 190.983L70.5246 187.794L72.2677 189.517L69.1198 192.727L72.2677 195.936L70.5246 197.659L67.3971 194.48L64.2799 197.659L62.5368 195.936Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M12.237 93.3152L15.3849 90.1057L12.237 86.8962L13.9801 85.1736L17.0973 88.3625L20.2247 85.1736L21.9679 86.8962L18.82 90.1057L21.9679 93.3152L20.2247 95.0378L17.0973 91.8591L13.9801 95.0378L12.237 93.3152Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M119.046 264.936L121.145 262.797L119.046 260.657L120.208 259.509L122.286 261.635L124.371 259.509L125.533 260.657L123.435 262.797L125.533 264.936L124.371 266.085L122.286 263.966L120.208 266.085L119.046 264.936Z" fill="#D7E8F5" fillOpacity="0.6" />
      </animated.g>
      <animated.g id="small" style={{ transform: `translate3d(0,${y * 1.2}px,0)` }} filter="url(#mediumBlur)">
        <path d="M434.383 137.245H438.261V140.049H434.383V144.432H431.429V140.049H427.54V137.245H431.429V133.045H434.383V137.245Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M364.383 96.3013H368.261V99.105H364.383V103.488H361.429V99.105H357.54V96.3013H361.429V92.1011H364.383V96.3013Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M704.876 207.202H708.754V210.006H704.876V214.388H701.922V210.006H698.033V207.202H701.922V203.002H704.876V207.202Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M570.884 248.805H574.762V251.609H570.884V255.991H567.93V251.609H564.041V248.805H567.93V244.605H570.884V248.805Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M619.781 132.059H612.196V129.985H619.781V132.059Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M595.535 209.719H587.95V207.644H595.535V209.719Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M692.751 37.7222H682.216V34.8413H692.751V37.7222Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M565.73 43.0037H552.634V39.5466H565.73V43.0037ZM565.73 49.3464H552.634V45.8894H565.73V49.3464Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M646.377 212.739H649.373V214.905H646.377V218.292H644.094V214.905H641.089V212.739H644.094V209.493H646.377V212.739Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M625.959 300.105H628.955V302.271H625.959V305.658H623.676V302.271H620.671V300.105H623.676V296.859H625.959V300.105Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M615.763 246.594H608.342V244.635H615.763V246.594ZM615.763 250.188H608.342V248.229H615.763V250.188Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M486.066 63.145H475.525V60.7866H486.066V63.145ZM479.134 57.8335C479.134 57.3687 479.288 56.9927 479.596 56.7056C479.91 56.4185 480.314 56.2749 480.806 56.2749C481.291 56.2749 481.691 56.4185 482.006 56.7056C482.327 56.9858 482.487 57.3618 482.487 57.8335C482.487 58.2915 482.334 58.6641 482.026 58.9512C481.718 59.2314 481.312 59.3716 480.806 59.3716C480.293 59.3716 479.886 59.228 479.586 58.9409C479.285 58.6538 479.134 58.2847 479.134 57.8335ZM479.134 66.1597C479.134 65.6948 479.288 65.3188 479.596 65.0317C479.91 64.7446 480.314 64.6011 480.806 64.6011C481.291 64.6011 481.691 64.7446 482.006 65.0317C482.327 65.312 482.487 65.688 482.487 66.1597C482.487 66.6177 482.334 66.9902 482.026 67.2773C481.718 67.5576 481.312 67.6978 480.806 67.6978C480.293 67.6978 479.886 67.5542 479.586 67.2671C479.285 66.98 479.134 66.6108 479.134 66.1597Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M490.93 199.877H479.385V197.294H490.93V199.877ZM483.339 194.06C483.339 193.551 483.507 193.139 483.844 192.825C484.188 192.51 484.63 192.353 485.169 192.353C485.701 192.353 486.139 192.51 486.483 192.825C486.835 193.132 487.011 193.543 487.011 194.06C487.011 194.562 486.842 194.97 486.506 195.284C486.169 195.591 485.723 195.745 485.169 195.745C484.608 195.745 484.162 195.587 483.833 195.273C483.503 194.958 483.339 194.554 483.339 194.06ZM483.339 203.179C483.339 202.67 483.507 202.258 483.844 201.944C484.188 201.629 484.63 201.472 485.169 201.472C485.701 201.472 486.139 201.629 486.483 201.944C486.835 202.251 487.011 202.663 487.011 203.179C487.011 203.681 486.842 204.089 486.506 204.403C486.169 204.71 485.723 204.864 485.169 204.864C484.608 204.864 484.162 204.707 483.833 204.392C483.503 204.078 483.339 203.673 483.339 203.179Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M682.456 101.191L685.604 97.9812L682.456 94.7717L684.199 93.0491L687.316 96.238L690.443 93.0491L692.187 94.7717L689.039 97.9812L692.187 101.191L690.443 102.913L687.316 99.7346L684.199 102.913L682.456 101.191Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M450.462 294.72L453.61 291.51L450.462 288.301L452.205 286.578L455.322 289.767L458.449 286.578L460.193 288.301L457.045 291.51L460.193 294.72L458.449 296.443L455.322 293.264L452.205 296.443L450.462 294.72Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M345.924 173.665L348.023 171.525L345.924 169.385L347.086 168.237L349.164 170.363L351.249 168.237L352.411 169.385L350.313 171.525L352.411 173.665L351.249 174.813L349.164 172.694L347.086 174.813L345.924 173.665Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M248.757 42.2451H252.635V45.0488H248.757V49.4316H245.803V45.0488H241.914V42.2451H245.803V38.0449H248.757V42.2451Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M10.673 175.306H14.5509V178.11H10.673V182.493H7.71889V178.11H3.83021V175.306H7.71889V171.106H10.673V175.306Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M304.374 162.894H308.252V165.698H304.374V170.08H301.42V165.698H297.531V162.894H301.42V158.694H304.374V162.894Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M170.383 204.497H174.261V207.301H170.383V211.684H167.429V207.301H163.54V204.497H167.429V200.297H170.383V204.497Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M83.8165 108.484H76.2316V106.41H83.8165V108.484Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M59.5704 186.143H51.9855V184.069H59.5704V186.143Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M156.786 14.147H146.251V11.2661H156.786V14.147Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M110.411 189.164H113.407V191.33H110.411V194.717H108.128V191.33H105.123V189.164H108.128V185.918H110.411V189.164Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M89.9938 276.53H92.9904V278.697H89.9938V282.083H87.7111V278.697H84.7062V276.53H87.7111V273.284H89.9938V276.53Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M79.797 223.019H72.3761V221.06H79.797V223.019ZM79.797 226.613H72.3761V224.654H79.797V226.613Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M132.355 142.15H121.814V139.792H132.355V142.15ZM125.423 136.839C125.423 136.374 125.577 135.998 125.885 135.711C126.199 135.424 126.603 135.28 127.095 135.28C127.58 135.28 127.98 135.424 128.295 135.711C128.616 135.991 128.777 136.367 128.777 136.839C128.777 137.297 128.623 137.669 128.315 137.956C128.007 138.237 127.601 138.377 127.095 138.377C126.582 138.377 126.175 138.233 125.875 137.946C125.574 137.659 125.423 137.29 125.423 136.839ZM125.423 145.165C125.423 144.7 125.577 144.324 125.885 144.037C126.199 143.75 126.603 143.606 127.095 143.606C127.58 143.606 127.98 143.75 128.295 144.037C128.616 144.317 128.777 144.693 128.777 145.165C128.777 145.623 128.623 145.995 128.315 146.282C128.007 146.563 127.601 146.703 127.095 146.703C126.582 146.703 126.175 146.559 125.875 146.272C125.574 145.985 125.423 145.616 125.423 145.165Z" fill="#D7E8F5" fillOpacity="0.6" />
      </animated.g>
      <animated.g id="xsmall" style={{ transform: `translate3d(0,${100 + y * 2}px,0)` }} filter="url(#bigBlur)">
        <path d="M531.502 191.511L534.65 188.302L531.502 185.092L533.245 183.37L536.362 186.559L539.489 183.37L541.233 185.092L538.085 188.302L541.233 191.511L539.489 193.234L536.362 190.055L533.245 193.234L531.502 191.511Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M481.203 88.8904L484.351 85.6809L481.203 82.4714L482.946 80.7488L486.063 83.9377L489.191 80.7488L490.934 82.4714L487.786 85.6809L490.934 88.8904L489.191 90.613L486.063 87.4343L482.946 90.613L481.203 88.8904Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M588.012 260.511L590.11 258.372L588.012 256.232L589.174 255.083L591.252 257.209L593.337 255.083L594.499 256.232L592.401 258.372L594.499 260.511L593.337 261.66L591.252 259.541L589.174 261.66L588.012 260.511Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M10.3829 120.245H14.2609V123.049H10.3829V127.432H7.42885V123.049H3.54018V120.245H7.42885V116.045H10.3829V120.245Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M242.551 283.519H246.429V286.322H242.551V290.705H239.597V286.322H235.708V283.519H239.597V279.318H242.551V283.519Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M540.973 255.245H544.851V258.049H540.973V262.432H538.019V258.049H534.13V255.245H538.019V251.045H540.973V255.245Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M266.797 351.408H270.675V354.212H266.797V358.594H263.843V354.212H259.954V351.408H263.843V347.208H266.797V351.408Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M625.225 116.255H629.985V119.696H625.225V125.075H621.6V119.696H616.827V116.255H621.6V111.101H625.225V116.255Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M315.693 191.744H308.109V189.67H315.693V191.744Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M291.445 297.35H283.86V295.276H291.445V297.35Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M388.663 64.4722H378.128V61.5913H388.663V64.4722Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M165.053 260.235H168.05V262.401H165.053V265.788H162.771V262.401H159.766V260.235H162.771V256.989H165.053V260.235Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M462.053 382.584H465.05V384.75H462.053V388.137H459.771V384.75H456.766V382.584H459.771V379.338H462.053V382.584Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M311.675 349.199H304.254V347.24H311.675V349.199ZM311.675 352.793H304.254V350.834H311.675V352.793Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M29.444 226.865H18.903V224.506H29.444V226.865ZM22.5123 221.553C22.5123 221.088 22.6662 220.712 22.9738 220.425C23.2882 220.138 23.6915 219.995 24.1837 219.995C24.6691 219.995 25.069 220.138 25.3834 220.425C25.7047 220.706 25.8654 221.082 25.8654 221.553C25.8654 222.011 25.7116 222.384 25.4039 222.671C25.0963 222.951 24.6896 223.091 24.1837 223.091C23.671 223.091 23.2643 222.948 22.9635 222.661C22.6627 222.374 22.5123 222.004 22.5123 221.553ZM22.5123 229.879C22.5123 229.415 22.6662 229.039 22.9738 228.751C23.2882 228.464 23.6915 228.321 24.1837 228.321C24.6691 228.321 25.069 228.464 25.3834 228.751C25.7047 229.032 25.8654 229.408 25.8654 229.879C25.8654 230.337 25.7116 230.71 25.4039 230.997C25.0963 231.277 24.6896 231.417 24.1837 231.417C23.671 231.417 23.2643 231.274 22.9635 230.987C22.6627 230.7 22.5123 230.331 22.5123 229.879Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M191.86 381.393H180.315V378.81H191.86V381.393ZM184.268 375.576C184.268 375.067 184.437 374.655 184.774 374.341C185.118 374.026 185.56 373.869 186.099 373.869C186.63 373.869 187.068 374.026 187.413 374.341C187.765 374.648 187.941 375.059 187.941 375.576C187.941 376.078 187.772 376.486 187.435 376.8C187.098 377.107 186.653 377.26 186.099 377.26C185.537 377.26 185.092 377.103 184.762 376.789C184.433 376.474 184.268 376.07 184.268 375.576ZM184.268 384.695C184.268 384.186 184.437 383.774 184.774 383.46C185.118 383.145 185.56 382.988 186.099 382.988C186.63 382.988 187.068 383.145 187.413 383.46C187.765 383.767 187.941 384.178 187.941 384.695C187.941 385.197 187.772 385.605 187.435 385.919C187.098 386.226 186.653 386.38 186.099 386.38C185.537 386.38 185.092 386.222 184.762 385.908C184.433 385.594 184.268 385.189 184.268 384.695Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M568.021 181.521L571.169 178.312L568.021 175.102L569.764 173.38L572.881 176.569L576.009 173.38L577.752 175.102L574.604 178.312L577.752 181.521L576.009 183.244L572.881 180.065L569.764 183.244L568.021 181.521Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M361.248 343.137L364.396 339.928L361.248 336.718L362.991 334.996L366.108 338.185L369.235 334.996L370.979 336.718L367.831 339.928L370.979 343.137L369.235 344.86L366.108 341.681L362.991 344.86L361.248 343.137Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M258.414 160.213L261.562 157.004L258.414 153.794L260.157 152.072L263.274 155.26L266.402 152.072L268.145 153.794L264.997 157.004L268.145 160.213L266.402 161.936L263.274 158.757L260.157 161.936L258.414 160.213Z" fill="#D7E8F5" fillOpacity="0.6" />
        <path d="M208.115 20.6633L211.263 17.4539L208.115 14.2444L209.858 12.5217L212.975 15.7107L216.103 12.5217L217.846 14.2444L214.698 17.4539L217.846 20.6633L216.103 22.386L212.975 19.2073L209.858 22.386L208.115 20.6633Z" fill="#D7E8F5" fillOpacity="0.6" />
      </animated.g>
    </svg>
  </MathSignsImg>
);
export default MathSigns;
