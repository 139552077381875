/** @jsx jsx */
import { SVGProps, useState, useEffect } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import StartPageContent from 'content/pages/startpage.yaml';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { jsx, Flex } from 'theme-ui';
import { Media } from 'utils/media';
import {
  animated, useSprings, to,
} from 'react-spring';


interface CapabilityProps {
  icon: IconProp;
  title: string;
}
const Capability = ({
  icon,
  title,
  ...props
}: CapabilityProps & SVGProps<SVGSVGElement>) => (
  <svg
    width="6%"
    height="6%"
    {...props}
  >
    <circle cx="0" cy="0" r="70%" />
    <FontAwesomeIcon icon={icon} x="-25%" y="-25%" width="50%" height="50%" />
    <foreignObject x="-100%" y="100%" width="200%" height="100%">
      <Flex
        sx={{
          position: 'fixed',
          left: '50%',
          transform: 'translateX(-50%)',
          justifyContent: 'center',
        }}
      >
        <p>
          {title}
        </p>
      </Flex>
    </foreignObject>
  </svg>
);

type Point = {x: any; y: any};
const size = 400;
const parallaxRatio = 0.05;

const Capabilities = () => {
  const { capabilities } = StartPageContent;
  const [initPoints, setInitPoints] = useState<Point[]>([]);

  const [points, setPoints, stop] = useSprings(
    initPoints.length,
    (i: number) => initPoints[i],
  );

  useEffect(() => {
    setInitPoints(
      capabilities.map((value: Point, index: number, list: Point[]) => {
        const point: Point = {
          x: (size / 2) * -1 + ((index) / list.length) * size + (70 * index),
          y: (((index + 2) % 3) * -70) - 60,
        };

        return point;
      }),
    );
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const el = document.getElementById('gatsby-focus-wrapper');
      if (!el || _.isEmpty(initPoints)) {
        return;
      }

      setPoints((i: number) => {
        const { x, y } = initPoints[i];

        return {
          x,
          y: y + el.scrollTop * (0.025 + (parallaxRatio * ((i + 2) % initPoints.length))),
        };
      });
    };

    const scrollEl = document.getElementById('gatsby-focus-wrapper');
    if (scrollEl) {
      scrollEl.addEventListener('scroll', _.throttle(handleScroll, 15));
      return () => {
        stop();
        scrollEl.removeEventListener('scroll', _.throttle(handleScroll, 15));
      };
    }
  }, [initPoints]);


  return (
    <Media
      className="capability-container"
      greaterThanOrEqual="sm"
    >
      <Flex
        sx={{
          maxHeight: [size, size, size, size * 1.2, size],
        }}
      >
        <svg
          viewBox={`${(size / 2) * -1} ${(size / 2) * -1} ${(size)} ${(size)}`}
          style={{
            height: '100%',
            width: '100%',
            padding: 80,
          }}
        >
          <defs>
            <marker
              id="head"
              orient="auto"
              markerWidth="10"
              markerHeight="10"
              strokeDasharray="1,0"
              refX="6"
              refY="3"
            >
              <path d="M3,0 L6,3  L3,6" fill="none" />
            </marker>
            <marker
              id="butt"
              orient="auto"
              markerWidth="10"
              markerHeight="10"
              strokeDasharray="1,0"
              refX="5"
              refY="3"
            >
              <path d="M6,0 L3,3  L6,6" fill="none" />
            </marker>
          </defs>
          <g>
            {capabilities.map(({ title, icon }: any, index: number) => {
              if (!points[index]) {
                return null;
              }
              const { x, y } = points[index];


              return (
                <animated.svg
                  key={title}
                  x={to([x], (x) => x)}
                  y={to([y], (y) => y)}
                >
                  <Capability
                    title={title}
                    icon={icon}
                  />
                </animated.svg>
              );
            })}
          </g>
          <g
            className="cap-line"
            fill="none"
            strokeWidth={1}
            strokeDasharray="1,4"
            strokeLinecap="round"
            markerEnd="url(#head)"
            markerStart="url(#butt)"
          >
            {points.map((startPoint: Point, index: number, list: Point[]) => {
              if (!list[index + 1]) {
                return null;
              }
              const endPoint = list[index + 1];
              const interpD = to([
                startPoint.x,
                startPoint.y,
                endPoint.x,
                endPoint.y,
              ], (sX: any, sY: any, eX: any, eY: any) => {
                const cX = _.mean([sX, eX]);
                const padding = 30;

                return `
                  M ${sX + padding}, ${sY}
                  C ${cX - padding / 10},${sY}
                    ${cX + padding / 10},${eY}
                    ${eX - padding}, ${eY}
                `;
              });

              return (
                <animated.path
              // eslint-disable-next-line react/no-array-index-key
                  key={`dot-line-${index}`}
                  d={interpD}
                />
              );
            })}
          </g>
        </svg>
      </Flex>
    </Media>
  );
};

export default Capabilities;
