/** @jsx jsx */
import Layout from 'components/layout';
import StartPageContent from 'content/pages/startpage.yaml';
import { Header1, HighlightText } from 'components/primitives/text';
import { jsx, Flex } from 'theme-ui';
import { PrimaryButton } from 'components/layout/button';
import Capabilities from 'components/layout/capabilities';
import MathSigns from 'components/layout/mathSigns';
import { Link } from 'gatsby';


const IndexPage = () => {
  const {
    slogan, seoDescription, buttonText, buttonHref,
  } = StartPageContent;

  return (
    <Layout
      title="Ekonomi"
      seoDescription={seoDescription}
      startPage
    >
      <Flex
        as="header"
        sx={{
          flexDirection: 'column',
          position: 'relative',
          mb: [0, 0, 0, 3, 6, 9],
        }}
      >
        <Flex
          sx={{
            width: '100%',
            alignItems: ['flex-start', 'flex-start', 'flex-start', 'center'],
            justifyContent: 'space-between',
            flexDirection: ['column', 'column', 'column', 'column', 'row'],
            mt: [5, 5, 6],
            mb: [0, 0, 3, 5, 6],
          }}
        >
          <Header1 className="title" sx={{ display: 'inline-block' }}>
            <HighlightText>{slogan}</HighlightText>
          </Header1>
          <Link
            to={buttonHref}
            sx={{
              display: 'flex',
              whiteSpace: 'nowrap',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'flex-end',
              mt: [5, 5, 5, 5, 0, 0],
            }}
          >
            <PrimaryButton
              icon="envelope-open-text"
              sx={{
                px: [5, 5, '2.5rem'],
              }}
            >
              {buttonText}
            </PrimaryButton>
          </Link>
        </Flex>
        <Flex sx={{ position: 'relative', mx: [0, 0, 0, 3, 5, 8, 5], mb: [8, 8, 2, 2, 8, 3, 8] }}>
          <Capabilities />
        </Flex>
        <Flex sx={{
          position: 'absolute',
          top: '20%',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '120%',
          zIndex: -1,
        }}
        >
          <MathSigns />
        </Flex>
      </Flex>
    </Layout>
  );
};

export default IndexPage;
